.Products-container{
     padding-top: 23%;  
     padding-bottom: 0;
}

.Product-title{
    color: #2B2E30;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    margin-left: 7.8%;
    margin-right: 7.8%;
    padding-left: 1%;
    padding-right: 1%;
    display: inline-block;
}

.Product-title span{
    color: rgb(255, 159, 28);
}

.Product-title h1{
    font-size: 36px;
    font-weight: 700;
}

.Product-title p{
    color: rgb(255, 159, 28);
    font-size: 20px;   
    letter-spacing: 1px; 
}

.Section-container{
    margin-top: 15%;
    margin-left: 16%;
    margin-right: 3%;
    padding-bottom: 2%;
}

.Sections-cards{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5% 0;
    margin-bottom: 0%;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    
    .Products-container{
        padding-top: 17%;  
   }
   
   .Product-title{
       color: #2B2E30;
       background-color: rgb(255, 255, 255);
       border-radius: 20px;
       margin-left: 7.8%;
       margin-right: 7.8%;
       padding-left: 1%;
       padding-right: 1%;
       display: inline-block;
   }
   
   .Product-title span{
       color: rgb(255, 159, 28);
   }
   
   .Product-title h1{
       font-size: 36px;
       font-weight: 700;
   }
   
   .Product-title p{
       color: rgb(255, 159, 28);
       font-size: 20px;   
       letter-spacing: 1px; 
   }
   
   .Section-container{
       margin-top: 15%;
       margin-left: 16%;
       margin-right: 10%;
       padding-bottom: 2%;
   }
   
   .Sections-cards{
       display: grid;
       grid-template-columns: repeat(2, 1fr);
       grid-gap: 0.5% 0;
       margin-bottom: 0%;
   }
    
  }
  

@media (min-width: 1024px){
    
    .Products-container{
        padding-top: 10%;
    }
    
    .Product-title{
        color: #2B2E30;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1%;
        padding-right: 1%;
        display: inline-block;
    }

    .Product-title h1{
        font-size: 41.6px;
        font-weight: 700;
    }
    
    .Product-title p{
        color: rgb(255, 159, 28);
        font-size: 16px;
        font-weight: 600;   
        letter-spacing: 1px;
        margin-top: 0%;
    }

    .Section-container{
        margin-top: 2%;
        margin-left: 10.7%;
        margin-right: 3.7%;
        padding-bottom: 2%;
        
    }

    .Sections-cards{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2% 0;
        margin-bottom: 0%;
    }

}