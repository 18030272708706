@media only screen and (max-width: 767px){
    .Integrations-Container{
        padding-top: 20%;
        padding-bottom: 0%;
        margin-left:7%;
        margin-right: 7%;
        background-color: rgb(255, 255, 255);
    }

    .title-search-container {
        align-items: center;
      }
    
    .Integrations-Container h2{
        font-size: 36px;
        font-weight: 700;
        color: #2B2E30;
        text-align: center;
        margin-right: auto;
    }

    .searchbar-container{
        margin-top: 10%;
        margin-bottom: 15%;
        display: flex;
        justify-content: center;
    }

    .searchbaricon{
        font-size: 32px;
        margin-top: 0%;
        margin-bottom: -1%;
        margin-right: 2%;
    }
    
    .searchBar{
        outline: none;
        border: none;
        border-bottom: 3px solid rgb(255, 159, 28);
        width: 60%;
        margin-right: 0%;
        color: #2B2E30;
        font-weight: 600;
    }

    .Integrations-Container span{
        color: rgb(255, 159, 28);
    }

    .cleanSearchButton{
        color: rgb(255, 159, 28);
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 600;
    }

    .NotFound{
        color: #2B2E30;
        width: 65vw;
        font-weight: 700;
        font-size: 25px;
        margin-top: 5%;
        text-align: center !important;
    }

    .NotFound span{
        color: rgb(255, 159, 28);
    }

    .ButtonIntegrationContainer{
        text-align: right;
        margin-right: 0%;
        padding-bottom: 2%;
    }

    .ButtonIntegration{
        color: rgb(255, 159, 28);
        padding-top: 5%;
        background-color: transparent;
        border: none;
        font-size: 15px;
        font-weight: 600;
    }

    .arrowUpbutton{
        font-size: 25px;
    }

    .arrowDownbutton{
        font-size: 25px;
        margin-top: -5%;
    }

    

    .Search-Row{
        border-radius: 20px;
        margin-bottom: 5%;
    }

    .Search-Row h2 {
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 10%;
        padding-top: 5%;
    }

    .YourSearchContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3%;
        margin-left: 5%;
        padding: 5% 0% 15% 5%;
        height: auto;
    }

    .IntegrationCard{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 0%;
        margin-left: 0%;
        margin-right: 2%;
    }

    .IntegrationCard img{
        width: 95px;
        height: auto;
        padding: 5%;
    }


    .DataEngineering-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .DataEngineering-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .DataEngineerContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1%;
        margin-left: 5%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataEngineerCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataEngineerCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Infraestructure-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Infraestructure-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .InfraestructureContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1%;
        margin-left: 5%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .InfraestructureCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .InfraestructureCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }
   
    .Data-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Data-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .DataContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Code-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Code-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .CodeContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.2%;
        padding: 5% 0% 5% 0%;
    }

    .CodeCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .CodeCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Apps-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Apps-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .AppsContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.2%;
        padding: 5% 0% 5% 0%;
    }

    .AppsCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .AppsCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Training-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Training-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .TrainingContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.2%;
        padding: 5% 0% 5% 0%;
    }

    .TrainingCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .TrainingCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }    

}


@media only screen and (min-width: 768px) and (max-width: 1023px){
    
    .Integrations-Container{
        padding-top: 17%;
        padding-bottom: 0%;
        margin-left: 7%;
        margin-right: 7%;
        background-color: rgb(255, 255, 255);
    }

    .title-search-container {
        align-items: center;
      }
    
    .Integrations-Container h2{
        font-size: 36px;
        font-weight: 700;
        color: #2B2E30;
        text-align: center;
        margin-right: auto;
    }

    .searchbar-container{
        margin-top: 10%;
        margin-bottom: 15%;
        display: flex;
        justify-content: center;
    }

    .searchbaricon{
        font-size: 32px;
        margin-top: 0%;
        margin-bottom: -1%;
        margin-right: 2%;
    }
    
    .searchBar{
        outline: none;
        border: none;
        border-bottom: 3px solid rgb(255, 159, 28);
        width: 40%;
        margin-right: 2%;
        color: #2B2E30;
        font-weight: 600;
    }

    .Integrations-Container span{
        color: rgb(255, 159, 28);
    }

    .cleanSearchButton{
        color: rgb(255, 159, 28);
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 600;
    }

    .NotFound{
        color: #2B2E30;
        width: 65vw;
        font-weight: 700;
        font-size: 25px;
        margin-top: 5%;
        text-align: center;
    }

    .NotFound span{
        color: rgb(255, 159, 28);
    }

    .ButtonIntegrationContainer{
        text-align: right;
        margin-right: 0%;
        padding-bottom: 2%;
    }

    .ButtonIntegration{
        color: rgb(255, 159, 28);
        padding-top: 5%;
        background-color: transparent;
        border: none;
        font-size: 15px;
        font-weight: 600;
    }

    .arrowUpbutton{
        font-size: 25px;
    }

    .arrowDownbutton{
        font-size: 25px;
        margin-top: -2%;
    }

    

    .Search-Row{
        border-radius: 20px;
        margin-bottom: 5%;
    }

    .Search-Row h2 {
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .YourSearchContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .IntegrationCard{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .IntegrationCard img{
        width: 100px;
        height: auto;
        padding: 5%;
    }


    .DataEngineering-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .DataEngineering-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .DataEngineerContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataEngineerCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataEngineerCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Infraestructure-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Infraestructure-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .InfraestructureContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .InfraestructureCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .InfraestructureCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }
   
    .Data-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Data-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .DataContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Code-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Code-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .CodeContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .CodeCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .CodeCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Apps-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Apps-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .AppsContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .AppsCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .AppsCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Training-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Training-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .TrainingContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .TrainingCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .TrainingCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    } 

}


@media (min-width: 1024px){
    
    .Integrations-Container{
        padding-top: 8%;
        padding-bottom: 0%;
        margin-left: 9.1%;
        margin-right: 9.1%;
        background-color: rgb(255, 255, 255);
    }

    .title-search-container {
        display: flex;
        align-items: center;
      }
    
    .Integrations-Container h2{
        font-size: 41.6px;
        font-weight: 700;
        color: #2B2E30;
        text-align: left;
        margin-right: auto;
    }

    .searchbar-container{
        display: flex;
        align-items: center;
    }

    .searchbaricon{
        font-size: 32px;
        margin-top: 0.2%;
        margin-right: 1%;
        margin-right: 5px;
    }
    
    .searchBar{
        width: 36.1vh;
        outline: none;
        border: none;
        border-bottom: 3px solid rgb(255, 159, 28);
        color: #2B2E30;
        font-weight: 600;
    }

    .Integrations-Container span{
        color: rgb(255, 159, 28);
    }

    .cleanSearchButton{
        color: rgb(255, 159, 28);
        background-color: transparent;
        border: none;
        font-weight: 600;
        font-size: 16px;
    }

    .NotFound{
        color: #2B2E30;
        width: 90vw;
        margin-left: -9.1% !important;
        font-weight: 700;
        font-size: 41.6px;
        text-align: center;
    }

    .NotFound span{
        color: rgb(255, 159, 28);
    }

    .ButtonIntegrationContainer{
        text-align: right;
        margin-right: 4.2%;
        padding-bottom: 2%;
    }

    .ButtonIntegration{
        color: #ff9f1c;
        background-color: transparent;
        border: none;
        font-size: 20px;
    }

    .arrowUpbutton{
        font-size: 30px;
    }

    .arrowDownbutton{
        font-size: 30px;
        margin-top: -2%;
    }
    

    .Search-Row{
        border-radius: 20px;
        margin-bottom: 2%;
    }

    .Search-Row h2 {
        color: rgb(255, 159, 28);
        text-align: left;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .YourSearchContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 15% 5%;
        height: auto;
    }

    .IntegrationCard{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .IntegrationCard img{
        width: 130px;
        height: auto;
        padding: 5%;
    }


    .DataEngineering-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .DataEngineering-Row h2{
        color: rgb(255, 159, 28);
        text-align: left;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .DataEngineerContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 2% 5%;
    }

    .DataEngineerCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataEngineerCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }

    .Infraestructure-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Infraestructure-Row h2{
        color: rgb(255, 159, 28);
        text-align:left;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .InfraestructureContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 5% 5%;
    }

    .InfraestructureCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .InfraestructureCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }
   
    .Data-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Data-Row h2{
        color: rgb(255, 159, 28);
        text-align: left;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .DataContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 5% 5%;
    }

    .DataCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataCards img{
        width: 130px;
        padding: 5%;
    }

    .Code-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Code-Row h2{
        color: rgb(255, 159, 28);
        text-align: left;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .CodeContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 5% 5%;
    }

    .CodeCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .CodeCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }

    .Apps-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Apps-Row h2{
        color: rgb(255, 159, 28);
        text-align: left;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .AppsContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 4% 5%;
    }

    .AppsCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .AppsCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }

    .Training-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Training-Row h2{
        color: rgb(255, 159, 28);
        text-align: left;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .TrainingContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 4% 5%;
    }

    .TrainingCards {
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }
    
    .TrainingCards img {
        width: 130px;
        height: auto;
        padding: 5%;
    }   

}


/* @media only screen and (max-width: 767px){
    .Integrations-Container{
        padding-top: 20%;
        padding-bottom: 0%;
        margin-left:7%;
        margin-right: 7%;
        background-color: rgb(255, 255, 255);
    }

    .title-search-container {
        align-items: center;
      }
    
    .Integrations-Container h2{
        font-size: 36px;
        font-weight: 700;
        color: #2B2E30;
        text-align: center;
        margin-right: auto;
    }

    .searchbar-container{
        margin-top: 10%;
        margin-bottom: 15%;
        display: flex;
        justify-content: center;
    }

    .searchbaricon{
        font-size: 32px;
        margin-top: 0%;
        margin-bottom: -1%;
        margin-right: 2%;
    }
    
    .searchBar{
        outline: none;
        border: none;
        border-bottom: 3px solid rgb(255, 159, 28);
        width: 60%;
        margin-right: 0%;
        color: #2B2E30;
        font-weight: 600;
    }

    .Integrations-Container span{
        color: rgb(255, 159, 28);
    }

    .cleanSearchButton{
        color: rgb(255, 159, 28);
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 600;
    }

    .NotFound{
        color: #2B2E30;
        width: 65vw;
        font-weight: 700;
        font-size: 25px;
        margin-top: 5%;
        text-align: center !important;
    }

    .NotFound span{
        color: rgb(255, 159, 28);
    }

    .ButtonIntegrationContainer{
        text-align: right;
        margin-right: 0%;
        padding-bottom: 2%;
    }

    .ButtonIntegration{
        color: rgb(255, 159, 28);
        padding-top: 5%;
        background-color: transparent;
        border: none;
        font-size: 15px;
        font-weight: 600;
    }

    .arrowUpbutton{
        font-size: 25px;
    }

    .arrowDownbutton{
        font-size: 25px;
        margin-top: -5%;
    }

    

    .Search-Row{
        border-radius: 20px;
        margin-bottom: 5%;
    }

    .Search-Row h2 {
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 10%;
        padding-top: 5%;
    }

    .YourSearchContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3%;
        margin-left: 5%;
        padding: 5% 0% 15% 5%;
        height: auto;
    }

    .IntegrationCard{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 0%;
        margin-left: 0%;
        margin-right: 2%;
    }

    .IntegrationCard img{
        width: 95px;
        height: auto;
        padding: 5%;
    }


    .DataEngineering-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .DataEngineering-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .DataEngineerContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1%;
        margin-left: 5%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataEngineerCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataEngineerCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Infraestructure-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Infraestructure-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .InfraestructureContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1%;
        margin-left: 5%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .InfraestructureCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .InfraestructureCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }
   
    .Data-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Data-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .DataContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Code-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Code-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .CodeContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.2%;
        padding: 5% 0% 5% 0%;
    }

    .CodeCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .CodeCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Apps-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Apps-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .AppsContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.2%;
        padding: 5% 0% 5% 0%;
    }

    .AppsCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .AppsCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }

    .Training-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Training-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .TrainingContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.2%;
        padding: 5% 0% 5% 0%;
    }

    .TrainingCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 18vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .TrainingCards img{
        width: 95px;
        height: auto;
        padding: 5%;
    }    

}


@media only screen and (min-width: 768px) and (max-width: 1023px){
    
    .Integrations-Container{
        padding-top: 17%;
        padding-bottom: 0%;
        margin-left: 7%;
        margin-right: 7%;
        background-color: rgb(255, 255, 255);
    }

    .title-search-container {
        align-items: center;
      }
    
    .Integrations-Container h2{
        font-size: 36px;
        font-weight: 700;
        color: #2B2E30;
        text-align: center;
        margin-right: auto;
    }

    .searchbar-container{
        margin-top: 10%;
        margin-bottom: 15%;
        display: flex;
        justify-content: center;
    }

    .searchbaricon{
        font-size: 32px;
        margin-top: 0%;
        margin-bottom: -1%;
        margin-right: 2%;
    }
    
    .searchBar{
        outline: none;
        border: none;
        border-bottom: 3px solid rgb(255, 159, 28);
        width: 40%;
        margin-right: 2%;
        color: #2B2E30;
        font-weight: 600;
    }

    .Integrations-Container span{
        color: rgb(255, 159, 28);
    }

    .cleanSearchButton{
        color: rgb(255, 159, 28);
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 600;
    }

    .NotFound{
        color: #2B2E30;
        width: 65vw;
        font-weight: 700;
        font-size: 25px;
        margin-top: 5%;
        text-align: center;
    }

    .NotFound span{
        color: rgb(255, 159, 28);
    }

    .ButtonIntegrationContainer{
        text-align: right;
        margin-right: 0%;
        padding-bottom: 2%;
    }

    .ButtonIntegration{
        color: rgb(255, 159, 28);
        padding-top: 5%;
        background-color: transparent;
        border: none;
        font-size: 15px;
        font-weight: 600;
    }

    .arrowUpbutton{
        font-size: 25px;
    }

    .arrowDownbutton{
        font-size: 25px;
        margin-top: -2%;
    }

    

    .Search-Row{
        border-radius: 20px;
        margin-bottom: 5%;
    }

    .Search-Row h2 {
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .YourSearchContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .IntegrationCard{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .IntegrationCard img{
        width: 100px;
        height: auto;
        padding: 5%;
    }


    .DataEngineering-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .DataEngineering-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .DataEngineerContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataEngineerCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataEngineerCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Infraestructure-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Infraestructure-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 5%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .InfraestructureContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .InfraestructureCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .InfraestructureCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }
   
    .Data-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Data-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .DataContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .DataCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Code-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Code-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .CodeContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .CodeCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .CodeCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Apps-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Apps-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .AppsContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .AppsCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .AppsCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    }

    .Training-Row{
        border-radius: 20px;
        margin-bottom: 10%;
    }

    .Training-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 5%;
        margin-top: 0%;
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .TrainingContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 0% 5% 0%;
        height: auto;
    }

    .TrainingCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 15vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .TrainingCards img{
        width: 100px;
        height: auto;
        padding: 5%;
    } 

}


@media (min-width: 1024px){
    
    .Integrations-Container{
        padding-top: 8%;
        padding-bottom: 0%;
        margin-left: 9.1%;
        margin-right: 9.1%;
        background-color: rgb(255, 255, 255);
    }

    .title-search-container {
        align-items: center;
      }
    
    .Integrations-Container h2{
        font-size: 41.6px;
        font-weight: 700;
        color: #2B2E30;
        text-align: center;
        margin-right: auto;
        margin-bottom: 5%;
    }

    .searchbar-container{
        align-items: center;
        text-align: center;
    }

    .searchbaricon{
        font-size: 32px;
        margin-top: 0.2%;
        margin-right: 1%;
        margin-right: 5px;
    }
    
    .searchBar{
        width: 36.1vh;
        outline: none;
        border: none;
        border-bottom: 3px solid rgb(255, 159, 28);
        color: #2B2E30;
        font-weight: 600;
    }

    .Integrations-Container span{
        color: rgb(255, 159, 28);
    }

    .cleanSearchButton{
        color: rgb(255, 159, 28);
        background-color: transparent;
        border: none;
        font-weight: 600;
        font-size: 16px;
    }

    .NotFound{
        color: #2B2E30;
        width: 90vw;
        margin-left: -9.1% !important;
        font-weight: 700;
        font-size: 41.6px;
        text-align: center;
    }

    .NotFound span{
        color: rgb(255, 159, 28);
    }

    .ButtonIntegrationContainer{
        text-align: right;
        margin-right: 4.2%;
        padding-bottom: 2%;
    }

    .ButtonIntegration{
        color: rgb(255, 159, 28);
        background-color: transparent;
        border: none;
        font-size: 20px;
    }

    .arrowUpbutton{
        font-size: 30px;
    }

    .arrowDownbutton{
        font-size: 30px;
        margin-top: -2%;
    }
    

    .Search-Row{
        border-radius: 20px;
        margin-bottom: 2%;
    }

    .Search-Row h2 {
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .YourSearchContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 15% 5%;
        height: auto;
    }

    .IntegrationCard{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .IntegrationCard img{
        width: 130px;
        height: auto;
        padding: 5%;
    }


    .DataEngineering-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .DataEngineering-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .DataEngineerContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 2% 5%;
    }

    .DataEngineerCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataEngineerCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }

    .Infraestructure-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Infraestructure-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .InfraestructureContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 5% 5%;
    }

    .InfraestructureCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .InfraestructureCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }
   
    .Data-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Data-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .DataContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 5% 5%;
    }

    .DataCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .DataCards img{
        width: 130px;
        padding: 5%;
    }

    .Code-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Code-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .CodeContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 5% 5%;
    }

    .CodeCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 10%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .CodeCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }

    .Apps-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Apps-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .AppsContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 4% 5%;
    }

    .AppsCards{
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .AppsCards img{
        width: 130px;
        height: auto;
        padding: 5%;
    }

    .Training-Row{
        border-radius: 20px;
        margin-bottom: -2%;
    }

    .Training-Row h2{
        color: rgb(255, 159, 28);
        text-align: center;
        font-size: 22px;
        margin-left: 0%;
        margin-top: 5%;
        padding-top: 2%;
    }

    .TrainingContainer{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1%;
        padding: 5% 5% 4% 5%;
    }

    .TrainingCards {
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.096);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 28vh;
        margin-bottom: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }
    
    .TrainingCards img {
        width: 130px;
        height: auto;
        padding: 5%;
    }   

} */