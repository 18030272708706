.Page-buttons{
    background-color: rgb(255, 159, 28);
    border: 3px solid rgb(255, 159, 28);
    border-radius: 15px;
    padding: 1.5% 2.5%;
    color: white;
    font-weight: bold;
    font-size: 14px;
    float: left;
    letter-spacing: 1px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
}

.Page-buttons:hover {
    background-color: rgb(255, 255, 255);
    border: 3px solid rgb(255, 159, 28);
    color: rgb(255, 159, 28);
}


@media (min-width: 790px) {

    .Page-buttons{
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        float: left;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .Page-buttons:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }

}