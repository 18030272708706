.Roles-container{
    background-color: rgb(255, 159, 28);
    overflow: hidden;
    margin-top: 40%;
    padding-top: 8.5%;
    padding-bottom: 8.5%;
}

.Roles-title{
    margin-left: 8.5%;
}

.Roles-title h2{
    color: #2B2E30;
    text-align: left;
    font-size: 30px;
    font-weight: 700;
}

.Roles-title span{
    color: white;
}

.RolesCards{
    margin-left: 0%;
    margin-right: 5%;
    margin-top: 10%;
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
    
    .Roles-Section{
        padding-top: 10%;
    }

    .Roles-container{
    background-color: rgb(255, 159, 28);
    overflow: hidden;
    margin-top: 0%;
    padding-top: 8.5%;
    padding-bottom: 8.5%;
}

.Roles-title{
    margin-left: 8.5%;
}

.Roles-title h2{
    color: #2B2E30;
    text-align: left;
    font-size: 30px;
    font-weight: 700;
}

.Roles-title span{
    color: white;
}

.RolesCards{
    margin-left: 0%;
    margin-right: 5%;
    margin-top: 10%;
}
}

@media (min-width: 1024px) {

    .Roles-Section{
        background-color: rgb(255, 255, 255);
        padding-top: 2%;
    }

    .Roles-container{
        margin-top: 10%;
        background-color: rgb(255, 159, 28);
        overflow: hidden;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 6%;
    }

    .Roles-title{
        margin-left: 9.1%;
    }

    .Roles-title h2{
        text-align: left;
        font-size: 41.6px;
        font-weight: 700;
    }

    .Roles-title span{
        color: white;
    }

    .Slider-container {
        margin-top: 5%;
        margin-left: 4.6%;
        margin-right: 10.8%;
      }

    .slick-prev,
    .slick-next {
        width: auto !important; /* Ajusta el ancho según tus necesidades */
        height: 18% !important; /* Ajusta la altura según tus necesidades */
    }

    .RolesCards{
        margin-left: 15%;
        margin-top: 0;
    }

}