
@media (max-width: 767px){
    .Landing-container{
        padding-top: 0%;
            width: 100%;
            height: 100vh;
            letter-spacing: 1px;
            background-image: url(../../assets/images/background-04.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
    }

    .information-container{
        width: 85%;
        padding-bottom: 15%;
        margin-left: 7.8%;
        margin-top: 12%;
        background-color: rgba(255, 255, 255, 0.81);
        border-radius: 20px;
    }

    .Title{
        text-align: left;
        margin-left: -0.5%;
        font-weight: bolder;
        font-size: 40px;
    }

    .Title span{
        color: rgb(255, 159, 28);
    }

    .information-container p {
        text-align: left;
        margin-top: 10%;
        margin-left: 0%;
        margin-bottom: 10%;
        font-weight: bold;
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .Landing-container{
        padding-top: 0%;
        width: 100%;
        height: 100vh;
        letter-spacing: 1px;
        background-image: url(../../assets/images/background-04.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    
    .information-container{
        width: 85%;
        padding-bottom: 15%;
        margin-left: 7.8%;
        margin-top: 12%;
        background-color: rgba(255, 255, 255, 0.81);
        border-radius: 20px;
    }
    
    .Title{
        text-align: left;
        margin-left: -0.5%;
        font-weight: bolder;
        font-size: 40px;
        color:#2B2E30;
    }
    
    .Title span{
        color: rgb(255, 159, 28);
    }
    
    .information-container p {
        text-align: left;
        margin-top: 10%;
        margin-left: 0%;
        margin-bottom: 10%;
        font-weight: bold;
        font-size: 16px;
        color: #2B2E30;
    }
  
}

@media (min-width: 1023px) {

    .Landing-container{
        padding-top: 11.5%;
        padding-bottom: 5%;
        width: 100%;
        height: 100vh;
        letter-spacing: 1px;
        display: flex;
        align-items:flex-start;
        justify-content: left;
        flex-direction: column;
        text-align: left;
    }

    .information-container {
        width: 60%;
        padding-bottom: 5%;
        margin-left: 9.1%;
        margin-top: 0%;
        background-color: rgba(255, 255, 255, 0.703);
        border-radius: 50px;
    }
    

    .Title{
        text-align: left;
        margin-left: -0.4%;
        font-weight: bolder;
        font-size: 58.5px;
        color: #2B2E30;

    }

    .Title span{
        color: rgb(255, 159, 28);
    }

    .information-container p {
        text-align: left;
        margin-top: 4%;
        margin-bottom: 2.5%;
        margin-left: 0%;
        font-weight: 600;
        font-size: 22.5px;
    }

    .ButtonLanding{
        margin-top: 5%;
    }

}