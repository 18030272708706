.Rolecard-container{
    width: 100%;
    margin-right: 0%;
    margin-left: 0%;
    padding: 2% 10%;
}

.Rolecard-information {
    text-align: left;
}

.Rolecard-information h3 {
    color: #2B2E30;
    position: relative;
    padding-bottom: 4%;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1px;
}
  
.Engineer-title::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 50%;
    width: 23%;
    height: 3px;
    background-color:#2B2E30;
}

.scientist-title::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 50%;
    width: 23%;
    height: 3px;
    background-color: #2B2E30;
}

.developer-title::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 50%;
    width: 21%;
    height: 3px;
    background-color: #2B2E30;
}

.executives-title::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 50%;
    width: 21%;
    height: 3px;
    background-color: #2B2E30;
}

.itarchitects-title::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 50%;
    width: 23%;
    height: 3px;
    background-color: #2B2E30;
}
  

.Rolecard-information p{
    font-size: 16px;
    color: white;
    font-weight: 600;
}

.role-button {
    float: right;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #2B2E30;
    margin-top: -2%;
    font-weight: 600;
}

.role-button:hover{
    color: rgb(255, 255, 255);
}

@media only screen and (min-width: 768px) and (max-width: 1023px){

    .Rolecard-container{
        width: 100%;
        margin-right: 0%;
        margin-left: 0%;
        padding: 2% 10%;
    }
    
    .Rolecard-information {
        text-align: left;
    }
    
    .Rolecard-information h3 {
        color: #2B2E30;
        position: relative;
        padding-bottom: 4%;
        font-size: 19px;
        font-weight: 700;
        letter-spacing: 1px;
    }
      
    .Engineer-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 23%;
        height: 3px;
        background-color:#2B2E30;
    }
    
    .scientist-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 23%;
        height: 3px;
        background-color: #2B2E30;
    }
    
    .developer-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 21%;
        height: 3px;
        background-color: #2B2E30;
    }
    
    .executives-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 21%;
        height: 3px;
        background-color: #2B2E30;
    }
    
    .itarchitects-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 23%;
        height: 3px;
        background-color: #2B2E30;
    }
      
    
    .Rolecard-information p{
        font-size: 16px;
        color: white;
        font-weight: 600;
    }
    
    .role-button {
        float: right;
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: #2B2E30;
        margin-top: 0%;
        font-weight: 600;
    }
    
    .role-button:hover{
        color: rgb(255, 255, 255);
    }

}

@media (min-width: 1024px) {
    .Rolecard-container{
        width: 70%;
        padding: 1% 1%;
    }

    .Rolecard-information {
        text-align: left;
    }

    .Rolecard-information h3 {
        color: #2B2E30;
        position: relative;
        padding-bottom: 4%;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 1px;
    }
      
    .Engineer-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 23%;
        height: 3px;
        background-color: #2B2E30;
    }

    .scientist-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 23%;
        height: 3px;
        background-color: #2B2E30;
    }

    .developer-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 21%;
        height: 3px;
        background-color: #2B2E30;
    }

    .executives-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 21%;
        height: 3px;
        background-color: #2B2E30;
    }

    .itarchitects-title::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 50%;
        width: 23%;
        height: 3px;
        background-color: #2B2E30;
    }
      

    .Rolecard-information p{
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: 600;
        color: white;
    }

    .role-button {
        float: right;
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: #2B2E30;
        margin-top: -2%;
        letter-spacing: 1px;
        font-size: 16px;
        font-weight: 600;
    }

    .role-button:hover{
        color: rgb(255, 255, 255);
    }
}