.Api-Page{
    padding: 4% 0%;
    background-image: url(../../assets/images/Apis/backgroundapi.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media( min-width: 720px){

.Api-Page{
    padding: 4% 0%;
    background-image: url(../../assets/images/Apis/backgroundapi.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

}