.Api-Container{
    margin-top: 20%;
    margin-bottom: 0%;
    background-color: rgba(255, 0, 0, 0);
    margin-left: 7.8%;
    margin-right: 7.8%;
}

.Api-title{
    background-color: rgba(255, 255, 255, 0);
    padding-right: 1%;
    margin-bottom: 10%;
}

.Api-title h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: rgb(255, 159, 28);
}

.Api-title span{
    color:  #2B2E30;
}

.Api-title p{
    text-align: center;
    font-size: 13.6px;
    margin: 0% 0%;
    font-weight: 600;
}

.Api-type{
   background-color: rgba(255, 255, 255, 0); 
   padding-left: 5%;
   padding-right: 8.5%;
   margin-bottom: 3%;
}

.Api-type1{
    padding-top: 5%;
    padding-left: 15%;
    padding-right: 12%;
    padding-bottom: 5%;
    border-radius: 15px;
}

.ApiImg {
    position: absolute;
    left: 0;
    margin-top: 0%;
    margin-left: 5%;
    width: 15%;
  }

.Apis-Section h3{
    text-align: center;
    margin-top: 0%;
    margin-bottom: 5%;
    padding-left: 0%;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    color: white;
    
}

.Apis-Section p{
    letter-spacing: 1px;
    margin-left: 0%;
    margin-top: 0%;
    text-align: center;
    padding: 0%;
    font-size: 13.6px;
    font-weight: 600;
    background-color: rgba(139, 96, 35, 0);
    color: white;
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
    .Api-Container{
        margin-top: 15%;
        margin-bottom: 15%;
        background-color: rgba(255, 255, 255, 0);
        margin-left: 7.8%;
        margin-right: 7.8%;
    }
    
    .Api-title{
        background-color: rgba(255, 255, 255, 0);
        padding-right: 1%;
        margin-bottom: 10%;
    }
    
    .Api-title h2 {
        text-align: center;
        font-size: 41.6px;
        font-weight: 700;
        color: rgb(255, 159, 28);
    }
    
    .Api-title span{
        color:  #2B2E30;
    }
    
    .Api-title p{
        text-align: center;
        font-size: 13.6px;
        margin: 0% 0%;
        font-weight: 600;
    }
    
    .Api-type{
       background-color: rgba(255, 255, 255, 0); 
       padding-left: 5%;
       padding-right: 8.5%;
       margin-bottom: 3%;
    }
    
    .Api-type1{
        padding-top: 5%;
        padding-left: 15%;
        padding-right: 12%;
        padding-bottom: 5%;
        border-radius: 15px;
    }
    
    .ApiImg {
        position: absolute;
        left: 0;
        margin-top: 0%;
        margin-left: 5%;
        width: 15%;
      }
    
    .Apis-Section h3{
        text-align: center;
        margin-top: 0%;
        margin-bottom: 5%;
        padding-left: 0%;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        color: white;
        
    }
    
    .Apis-Section p{
        letter-spacing: 1px;
        margin-left: 0%;
        margin-top: 0%;
        text-align: center;
        padding: 0%;
        font-size: 13.6px;
        font-weight: 600;
        background-color: rgba(139, 96, 35, 0);
        color: white;
    }
}

@media (min-width:1024px){
    
    .Api-Container{
        margin-top: 5%;
        margin-bottom: 5%;
        background-color: rgba(255, 255, 255, 0);
        margin-left: 26.8%;
        margin-right: 25%;
    }
    
    .Api-title{
        background-color: rgba(255, 255, 255, 0);
        padding-right: 1%;
        margin-bottom: 10%;
    }
    
    .Api-title h2 {
        text-align: center;
        font-size: 41.6px;
        font-weight: 700;
        color: rgb(255, 159, 28);
    }

    .Api-title span{
        color:  #2B2E30;
    }

    .Api-title p{
        text-align: center;
        font-size: 16px;
        font-weight: 600;
    }

    .Api-type{
       background-color: rgba(255, 255, 255, 0); 
       padding-left: 5%;
       padding-right: 8.5%;
       margin-bottom: 3%;
    }

    .Api-type1{
        padding-top: 2%;
        padding-left: 8%;
        padding-right: 7%;
        padding-bottom: 2%;
        border-radius: 15px;
    }
    
    .ApiImg {
        position: absolute;
        left: 0;
        margin-top: 0%;
        margin-left: 26.7%;
        width: 5%;
      }

    .Apis-Section h3{
        text-align: left;
        margin-top: 0%;
        margin-bottom: 1.5%;
        padding-left: 0%;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        color: white;
        
    }

    .Apis-Section p{
        letter-spacing: 1px;
        margin-left: 0%;
        margin-top: 0%;
        text-align: left;
        padding: 0%;
        font-size: 16px;
        font-weight: 600;
        background-color: rgba(139, 96, 35, 0);
        color: white;
    }
}