.Productcard-container{
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: auto;
    padding: 5% 10%;
    margin-bottom: 2%;
    margin-left: -8%;
}

.Image-container{
    background-color: rgb(255, 159, 28);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 15%;    
}

.Image-container img{
    width: 80%;
    height: auto;
}

.Productcard-information{
    text-align: center;
    color: rgb(255, 159, 28);
}

.Productcard-information h3{
    font-weight: 500;
    font-size: 25px;
}

.Productcard-information p{
    font-size: 17px;
    color: #000;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .Productcard-container{
        background-color: rgb(255, 255, 255);
        width: 100%;
        height: auto;
        padding: 5% 10%;
        margin-bottom: 2%;
        margin-left: -8%;
    }
    
    .Image-container{
        background-color: rgb(255, 159, 28);
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 15%;    
    }
    
    .Image-container img{
        width: 80%;
        height: auto;
    }
    
    .Productcard-information{
        text-align: center;
        color: rgb(255, 159, 28);
    }
    
    .Productcard-information h3{
        font-weight: 500;
        font-size: 25px;
    }
    
    .Productcard-information p{
        font-size: 17px;
        color: #000;
    }
}

@media (min-width: 1024px){

    .Productcard-container{
        background-color: rgb(255, 255, 255);
        width: 68%;
        height: auto;
        padding: 5% 0%;
        margin-bottom: 2%;
    }

    .Image-container{
        background-color: rgb(255, 159, 28);
        border-radius: 50%;
        width: 96px;
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 15%;    
    }

    .Image-container img{
        width: 68%;
        height: auto;
    }

    .Productcard-information{
        text-align: center;
        color: rgb(255, 159, 28);
    }

    .Productcard-information h3{
        font-weight: 700;
        font-size:  21.6px;
    }

    .Productcard-information p{
        font-size: 13.6px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #000;
    }

}