.WhoWeAre-container{
    padding-top: 36%;
    margin-left: 7.8%;
    margin-right: 7.8%;
}

.about-container{
    align-items: center;
    background-color: rgb(255, 255, 255);
        
}

.about-title{
    text-align: center;
    margin-top: -20.5%;
    margin-bottom: 10%;
    background-color: rgb(255, 255, 255);
}

.about-title h2{
    margin-bottom: 5%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 36px;
    font-weight: 700;
    color: #2B2E30;
}

.about-title p{
    margin: 0 8%;
    font-size: 13.6px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
}

.about-title span{
    color: rgb(255, 159, 28);
}


.about-img img{
    width: 80%;
    transform: rotate(90deg);       
}

.Mission-container{
    margin-top: 20%;
}

.Mission-container h2{
    margin-bottom: 4%;
    letter-spacing: 1px;
    font-size: 36px;
    font-weight: 700;
    color: #2B2E30;
}

.Mission-container span{
    color: rgb(255, 159, 28);    
}

.whocard-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3%;
    margin-bottom: 0%;
    margin-top: 20%;
}

.mission-card{
    background-color: rgb(255, 159, 28);
    border-radius: 20px;
    padding: 5% 9%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.Flag-img {
    text-align: center;
    margin-bottom: 4%;
  }

.Flag-img img {
    width: 9.6%;
}

.mission-card p {
    font-size: 13.6px;
    font-weight: 600;
    letter-spacing: 1px;
    color: white;
    text-align: center;
}

.mission-card span{
    color: #2B2E30;
}

.vision-card{
    background-color: #2B2E30;
    border-radius: 20px;
    padding: 5% 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.Glass-img{
    text-align: center;
    margin-bottom: 10%;
}

.Glass-img img{
    width: 12.8%;
}

.vision-card p{
    font-size: 13.6px;
    font-weight: 600;
    letter-spacing: 1px;
    color: white;
    text-align: center;
}

.vision-card span{
    color: rgb(255, 159, 28);
}

@media only screen and (min-width: 768px) and (max-width: 1139px){

    .WhoWeAre-container{
        padding-top: 34%;
        margin-left: 7.8%;
        margin-right: 7.8%;
    }
    
    .about-container{
        align-items: center;
        background-color: rgb(255, 255, 255);
            
    }
    
    .about-title{
        text-align: center;
        margin-top: -20.5%;
        margin-bottom: 10%;
        background-color: rgb(255, 255, 255);
    }
    
    .about-title h2{
        margin-bottom: 5%;
        text-align: center;
        letter-spacing: 1px;
        font-size: 41.6px;
        font-weight: 700;
        color: #2B2E30;
    }
    
    .about-title p{
        margin: 0 8%;
        font-size: 13.6px;
        font-weight: 600;
        background-color: rgb(255, 255, 255);
    }
    
    .about-title span{
        color: rgb(255, 159, 28);
    }
    
    
    .about-img img{
        width: 70%;
        transform: rotate(90deg);       
    }
    
    .Mission-container{
        margin-top: 15%;
    }
    
    .Mission-container h2{
        margin-bottom: 4%;
        letter-spacing: 1px;
        font-size: 41.6px;
        font-weight: 700;
        color: #2B2E30;
    }
    
    .Mission-container span{
        color: rgb(255, 159, 28);    
    }
    
    .whocard-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3%;
        margin-bottom: 0%;
    }
    
    .mission-card{
        background-color: rgb(255, 159, 28);
        border-radius: 20px;
        padding: 5% 9%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    
    .Flag-img {
        text-align: center;
        margin-bottom: 4%;
      }
    
    .Flag-img img {
        width: 9.6%;
    }
    
    .mission-card p {
        font-size: 13.6px;
        font-weight: 600;
        letter-spacing: 1px;
        color: white;
        text-align: center;
    }
    
    .mission-card span{
        color: #2B2E30;
    }
    
    .vision-card{
        background-color: #2B2E30;
        border-radius: 20px;
        padding: 5% 9%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    
    .Glass-img{
        text-align: center;
        margin-bottom: 10%;
    }
    
    .Glass-img img{
        width: 12.8%;
    }
    
    .vision-card p{
        font-size: 13.6px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;
        text-align: center;
    }
    
    .vision-card span{
        color: rgb(255, 159, 28);
    }

}

@media(min-width:1140px){
    
    .WhoWeAre-container{
        padding-top: 12%;
        margin-left: 13.6%;
        margin-right: 9.1%;
    }

    .about-container{
        display: flex; 
        align-items: center;
        background-color: rgb(255, 255, 255);
            
    }

    .about-title{
        text-align: left;
        margin-right: 10%;
        width: 45%;
        margin-top: -19%;
        background-color: rgb(255, 255, 255);
    }

    .about-title h2{
        margin-bottom: 5%;
        text-align: left;
        letter-spacing: 1px;
        font-size: 41.6px;
        font-weight: 700;
        color: #2B2E30;
    }

    .about-title p{
        width: 86%;
        margin-left: 0%;
        font-size: 16px;
        font-weight: 600;
        background-color: rgb(255, 255, 255);
    }

    .about-title span{
        color: rgb(255, 159, 28);
    }
    

    .about-img img{
        width: 36vw;
        height: 64vh; 
        transform: rotate(0deg);        
    }

    .Mission-container{
        margin-top: 8%;
    }

    .Mission-container h2{
        margin-bottom: 4%;
        letter-spacing: 1px;
        font-size: 41.6px;
        font-weight: 700;
        color: #2B2E30;
    }

    .Mission-container span{
        color: rgb(255, 159, 28);    
    }

    .whocard-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3%;
        margin-bottom: 0%;
        margin-top: 8%;
    }

    .mission-card{
        background-color: rgb(255, 159, 28);
        border-radius: 20px;
        padding: 9% 9%;
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .Flag-img {
        text-align: left;
        margin-bottom: 5%;
      }

    .Flag-img img {
        width: 11%;
        margin-top: 2%;
    }

    .mission-card p {
        font-size: 100%;
        font-weight: 600;
        letter-spacing: 1px;
        color: white;
        text-align: left;
    }

    .mission-card span{
        color: #2B2E30;
    }

    .vision-card{
        background-color: #2B2E30;
        border-radius: 20px;
        padding: 9% 9%;
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .Glass-img{
        text-align: left;
        margin-bottom: 10%;
        margin-top: 2%;
    }

    .Glass-img img{
        width: 15%;
    }

    .vision-card p{
        font-size: 100%;
        font-weight: 600;
        padding-bottom: 0%;
        letter-spacing: 1px;
        color: white;
        text-align: left;
    }

    .vision-card span{
        color: rgb(255, 159, 28);
    }

}