.PopUp-Header {
  border-bottom: 3px solid rgb(255, 159, 28);
  margin: 0% 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PopUp-Header h2 {
  font-size: 20px;
  font-weight: 700;
  color: rgb(255, 159, 28);
  margin-top: 5%;
}

.Close-ButtonPopup {
  font-size: 30px;
  color: rgb(255, 159, 28);
}

.Close-ButtonPopup:hover{
  color: #2B2E30;
}

.PopUp-Body{
  margin: 5% 5% 2% 5%;
}

.PopUp-Body p {
  font-size: 15px;
  font-weight: 600;
  color: #2B2E30;
}

.PopUp-Body span{
  color: rgb(255, 159, 28)
}



@media (min-width:790px){
  
  body.modal-open {
    height: 100vh !important;
    width: 100vw !important;
    overflow-y: scroll !important;
  }
  
  .PopUp-Header {
    border-bottom: 3px solid rgb(255, 159, 28);
    margin: 0% 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .PopUp-Header h2 {
    font-size: 20px;
    font-weight: 600;
    color: rgb(255, 159, 28);
    margin-top: 5%;
  }

  .Close-ButtonPopup {
    font-size: 30px;
    color: rgb(255, 159, 28);
  }

  .Close-ButtonPopup:hover{
    color: #2B2E30;
  }

  .PopUp-Body{
    margin: 5% 5% 2% 5%;
  }

  .PopUp-Body p {
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #2B2E30;
  }

  .PopUp-Body span{
    color: rgb(255, 159, 28)
  }

}