.contact {
    background-image: url(../../assets/images/background-04.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 17%;
  }
  

.contact-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3%;
    padding-top: 1%;
    padding-bottom:  5%;
    margin-left: 9.1%;
    margin-right: 9.1%;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
}


.ContactTitlecontainer{
    color: #2B2E30;
    text-align: center;
    margin-top: 2%;
}

.ContactTitlecontainer h2{
    margin-bottom: 4%;
    letter-spacing: 1px;
    font-size: 36px;
    font-weight: 700;
}

.ContactTitlecontainer span{
    color: rgb(255, 159, 28);
}

.ContactTitlecontainer p{
    margin: 0 5%;
    font-size: 13.6px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}

.button-submition{
    margin-top: 4%;
    float: right;
    background-color: rgb(255, 159, 28);
    border: 3px solid rgb(255, 159, 28);
    border-radius: 15px;
    padding: 1.5% 2.5%;
    color: white;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1.5px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
}

.button-submition:hover{
    background-color: rgb(255, 255, 255);
    border: 3px solid rgb(255, 159, 28);
    color: #ff9f1c;
}

.button-Loading{
    margin-top: 4%;
    float: right;
    background-color: rgb(255, 159, 28);
    border: 3px solid rgb(255, 159, 28);
    border-radius: 15px;
    padding: 1.5% 2.5%;
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1.5px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-contact{
    margin-right: 2%;
    margin-top: 4%;
    text-align: left;
}

.input-email{
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2.5%;
    padding-left: 3%;
    padding-right: 5%;
    outline: none;
    margin-bottom: 5%;
    border-radius: 15px;
    border: 2px rgb(170, 170, 170) solid;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
}

.textarea-m{
    width: 100%;
    height: 30vh !important;
    padding-top: 2%;
    padding-bottom: 2.5%;
    padding-left: 3%;
    padding-right: 5%;
    outline: none;
    border-radius: 15px;
    border: 2px rgb(170, 170, 170) solid;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    overflow-wrap: break-word;
    resize: none;
    overflow: hidden;
}

.recaptcha-container{
    padding-top: 5%;
}

.Footercontact{
    margin-top: 10%;
    padding: 2% 9.1%;
    padding-top: 8%;
    background-color: #2B2E30;
    text-align: left;
}

.Footer-logo{
    width: 20%;
    margin-right: auto;
    cursor: pointer;
    margin-bottom: 10%;
}

.allsections{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0%;
    padding-top: 5%;
    padding-left: 0%;
}

.footer-section {
    margin-bottom: 8%;
    text-align: left;
    color: rgb(255, 255, 255);
}

.footer-section {
    margin-right: 1%;
}

.footer-section:hover {
    cursor: pointer;
  }

.footer-section h2{
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
  
.footer-section ul li {
    margin: 5% 0;
    font-size: 13px;
    color: rgb(255, 255, 255);
}

.icon-container-footer{
    margin-top: 4%;
    text-align: left !important;
    align-items: center !important;
}

.icon-container-footer p{
    margin-top: 0%;
    font-size: 13px;
    color: rgb(255, 255, 255);
    margin-left: 0.5%;
}

.Footer-icon{
    width: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 1023px){

    .contact {
        background-image: url(../../assets/images/background-04.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        padding-top: 15%;
      }
      
    
    .contact-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3%;
        padding-top: 1%;
        padding-bottom:  5%;
        margin-left: 9.1%;
        margin-right: 9.1%;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
    }
    
    .ContactTitlecontainer{
        color: #2B2E30;
        text-align: center;
        margin-top: 2%;
    }
    
    .ContactTitlecontainer h2{
        margin-bottom: 4%;
        letter-spacing: 1px;
        font-size: 41.6px;
        font-weight: 700;
    }
    
    .ContactTitlecontainer span{
        color: rgb(255, 159, 28);
    }
    
    .ContactTitlecontainer p{
        margin: 0 5%;
        font-size: 13.6px;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;
    }
    
    .button-submition{
        margin-top: 4%;
        float: right;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 1.5px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .button-submition:hover{
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }
    
    .form-contact{
        margin-right: 2%;
        margin-top: 4%;
        text-align: left;
    }
    
    .input-email{
        width: 100%;
        padding-top: 1%;
        padding-bottom: 1.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        margin-bottom: 5%;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .textarea-m{
        width: 100%;
        height: 30vh !important;
        padding-top: 1%;
        padding-bottom: 2.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
        overflow-wrap: break-word;
        resize: none;
        overflow: hidden;
    }

    .Footercontact{
        margin-top: 10%;
        padding: 0% 7%;
        padding-top: 5%;
        background-color: #2B2E30;
        text-align: left;
    }
    
    .Footer-logo{
        width: 10%;
        margin-top: 0%;
        margin-bottom: 5%;
        cursor: pointer;
        text-align: center !important;
        align-items: center !important;
    }

    .allsections{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0%;
        padding-top: 0%;
        padding-left: 0%;
    }

    .footer-section {
        margin-right: 20px;
        text-align: left;
        color: white;
    }

    .footer-section {
        margin-right: 1%;
    }

    .footer-section:hover {
        cursor: pointer;
      }

    .footer-section h2{
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        
    }

    .footer-section ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
      
    .footer-section ul li {
        margin-left: 0px;
        font-size: 13px;
    }

    .icon-container-footer{
        margin-top: 4%;
        text-align: left !important; 
        align-items: center;
    }
    
    .icon-container-footer p{
        margin-top: 0%;
        font-size: 13px;
        color: rgb(255, 255, 255);
        margin-left: 0.5%;
        padding-bottom: 5%;
        margin-bottom: 0;
    }
    
    .Footer-icon{
        width: 5%;
        margin-left: 0%;
        margin-right: 2%;
        margin-bottom: 2%;
        cursor: pointer;
    }
}

@media (min-width: 1024px) {
    
    .contact {
        background-image: url(../../assets/images/background-04.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        padding-top: 12%;
      }
      

    .contact-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3%;
        padding-top: 1%;
        padding-bottom:  5%;
        margin-left: 9.1%;
        margin-right: 9.1%;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
    }
    
    .ContactTitlecontainer{
        color: #2B2E30;
        text-align: left;
        width: 65%;
        margin-left: 10%;
        margin-right: 5%;
        margin-top: 2%;
    }

    .ContactTitlecontainer h2{
        margin-bottom: 4%;
        letter-spacing: 1px;
        font-size: 41.6px;
        font-weight: 700;
    }

    .ContactTitlecontainer span{
        color: rgb(255, 159, 28);
    }

    .ContactTitlecontainer p{
        font-size: 13.6px;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: left;
        margin-left: 0;
    }

    .button-submition{
        margin-top: 4%;
        float: right;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 1.5px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }

    .button-submition:hover{
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }

    .form-contact{
        margin-right: 2%;
        margin-top: 4%;
        text-align: left;
    }

    .input-email{
        width: 100%;
        padding-top: 2%;
        padding-bottom: 2.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        margin-bottom: 5%;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
        overflow: hidden;
    }

    .textarea-m {
        width: 100%;
        height: 30vh !important;
        padding-top: 2%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
        overflow-wrap: break-word;
        resize: none;
        overflow: hidden;
    }
    

    .Footercontact{
        margin-top: 6%;
        padding: 2% 9.1%;
        padding-top: 3%;
        background-color: #2B2E30;
        display: flex;
        align-items: center;
    }

    .Footer-logo{
        width: 6vw;
        margin-top: 0%;
        cursor: pointer;
    }

    .allsections{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0%;
        padding-top: 1%;
        padding-left: 8%;
    }

    .footer-section {
        margin-right: 20px;
        text-align: left;
        color: white;
    }

    .footer-section {
        margin-right: 1%;
    }

    .footer-section:hover {
        cursor: pointer;
      }

    .footer-section h2{
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        
    }

    .footer-section ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
      
    .footer-section ul li {
        margin-left: 0px;
        font-size: 13px;
    }

    .Who-list{
        width: 10vw;
    }

    .icon-container-footer{
        width: 20%;
        margin-top: -6.5%;
        margin-left: 15%;
    }

    .icon-container-footer p{
        margin-top: 0%;
        font-size: 13px;
        color: rgb(255, 255, 255);
        margin-left: 1%;
    }

    .Footer-icon{
        width: 3.2vw;
        padding-right: 10%;
        margin-top: 0%;
        cursor: pointer;
    }
}