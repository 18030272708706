/* Estilos para modo mobile */

.navbar-toggler{
    color: transparent !important;
}

@media (max-width: 692px) {
  
    .Toggle{
        border: none;
        top: 0;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: 2%;
        margin-top: -18%;
    }
    
    
    .navbar-custom {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1005 !important; 
        box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
        color: black !important;
        font-weight: bold;
        background-color: rgb(255, 255, 255) !important;
        letter-spacing: 1px !important;
        height: auto;
        padding-top: 4%;
        padding-bottom: 2%;
        
    }
    
    
    .Navbar-logo{
        width: 15%;
        margin-left: 7.2%;
        margin-bottom: 1%;
        margin-top: 0%;
    }
    
      
    .product-link {
        margin-top: 2%;
        margin-bottom: -2.5% !important;
        margin-left: -0% !important;
        
    }

    .SolutionNav{
        text-align: center !important;
        margin-left: -0% !important
    }
    
    .api-nav{
        margin-left: -0% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .integration-nav{
        margin-left: -0% !important;
        margin-right: 0% !important;
        margin-top: -3% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .who-nav{
        margin-left: -0% !important;
        margin-top: -2.5%;
        margin-left: auto;
        background-color: rgba(255, 0, 0, 0);
    }
    
    .contact-button{
        margin-top: 4.2%;
        margin-bottom: 5%;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.7% 2%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .contact-button:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }
    

}

/* Estilos para modo tablet */

@media (min-width: 693px) and (max-width: 768px){

    .Toggle{
        border: none;
        margin-left: auto;
        margin-right: 5%;
        background-color: rgb(255, 255, 255);
        padding: 5%;
    }
    .Toggle:active{
        outline:  none !important;
    }
    
    .Toggle::after{
        outline: none !important;
    }
    
    .navbar-custom {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1005 !important; /* Cambia el valor de z-index */
        box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
        color: black !important;
        font-weight: bold;
        background-color: rgb(255, 255, 255) !important;
        letter-spacing: 1px !important;
        height: auto;
        padding-top: 4%;
        padding-bottom: 2%;
        
    }
    
    
    .Navbar-logo{
        width: 12%;
        margin-left: 7.2%;
        margin-bottom: auto;
        padding-bottom: 0%;
        margin-top: 0%;
        position: static;
    }
    
    .product-link {
        margin-top: 2%;
        margin-bottom: -2.5% !important;
        margin-left: -22% !important;
        
    }

    .SolutionNav{
        text-align: center !important;
        margin-left: -20.2% !important
    }
    
    .api-nav{
        margin-left: -21.5% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .integration-nav{
        margin-left: -19.5% !important;
        margin-right: 0% !important;
        margin-top: -3% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .who-nav{
        margin-left: -19.5% !important;
        margin-top: -2.5%;
        margin-left: auto;
        background-color: rgba(255, 0, 0, 0);
    }
    
    .contact-button{
        margin-left: -19%;
        margin-top: 4.2%;
        margin-bottom: 5%;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.7% 2%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .contact-button:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }

}

@media (min-width: 768px) and (max-width: 869px) {
    .Toggle{
        border: none;
        margin-left: auto;
        margin-right: 5%;
        background-color: rgb(255, 255, 255);
        padding: 5%;
        margin-top: -13%;
    }
    .Toggle:active{
        outline:  none !important;
    }
    
    .Toggle::after{
        outline: none !important;
    }
    
    .navbar-custom {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1005 !important; /* Cambia el valor de z-index */
        box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
        color: black !important;
        font-weight: bold;
        background-color: rgb(255, 255, 255) !important;
        letter-spacing: 1px !important;
        height: auto;
        padding-top: 4%;
        padding-bottom: 2%;
        
    }
    
    
    .Navbar-logo{
        width: 10%;
        margin-left: 7.2%;
        margin-bottom: auto;
        padding-bottom: 1%;
        margin-top: 0%;
    }
    
    .product-link {
        margin-top: 2%;
        margin-bottom: -2% !important;
        margin-left: 0% !important;
        
    }

    .SolutionNav{
        text-align: center !important;
        margin-left: 0% !important
    }
    
    .api-nav{
        margin-left: 0% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .integration-nav{
        margin-left: 0% !important;
        margin-right: 0% !important;
        margin-top: -2% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .who-nav{
        margin-left: 0% !important;
        margin-top: -2%;
        margin-left: auto;
        background-color: rgba(255, 0, 0, 0);
    }
    
    .contact-button{
        margin-left: 0%;
        margin-top: 4.2%;
        margin-bottom: 5%;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.7% 2%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .contact-button:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }
}


@media (min-width: 870px) and (max-width: 890px) {
    .Toggle{
        border: none;
        margin-left: auto;
        margin-right: 0%;
        background-color: rgb(255, 255, 255);
        padding: 5%;
        margin-top: -15%;
    }
    .Toggle:active{
        outline:  none !important;
    }
    
    .Toggle::after{
        outline: none !important;
    }
    
    .navbar-custom {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1005 !important; /* Cambia el valor de z-index */
        box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
        color: black !important;
        font-weight: bold;
        background-color: rgb(255, 255, 255) !important;
        letter-spacing: 1px !important;
        height: auto;
        padding-top: 2%;
        padding-bottom: 2%;
        
    }
    
    
    .Navbar-logo{
        width: 12%;
        margin-left: 7.2%;
        margin-bottom: auto;
        padding-bottom: 0%;
        margin-top: 0%;
        position: static;
    }
    
    .product-link {
        margin-top: 2%;
        margin-bottom: -2% !important;
        margin-left: -0% !important;
        
    }

    .SolutionNav{
        text-align: center !important;
        margin-left: -0% !important
    }
    
    .api-nav{
        margin-left: -0% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .integration-nav{
        margin-left: -0% !important;
        margin-right: 0% !important;
        margin-top: -2% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .who-nav{
        margin-left: -0% !important;
        margin-top: -2%;
        margin-left: auto;
        background-color: rgba(255, 0, 0, 0);
    }
    
    .contact-button{
        margin-left: -0%;
        margin-top: 4.2%;
        margin-bottom: 5%;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.7% 2%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .contact-button:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }
}


@media (min-width: 891px) and (max-width: 925px) {
    .Toggle{
        border: none;
        margin-left: auto;
        margin-right: 5%;
        background-color: rgb(255, 255, 255);
        padding: 5%;
    }
    .Toggle:active{
        outline:  none !important;
    }
    
    .Toggle::after{
        outline: none !important;
    }
    
    .navbar-custom {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1005 !important; /* Cambia el valor de z-index */
        box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
        color: black !important;
        font-weight: bold;
        background-color: rgb(255, 255, 255) !important;
        letter-spacing: 1px !important;
        height: auto;
        padding-top: 4%;
        padding-bottom: 2%;
        
    }
    
    
    .Navbar-logo{
        width: 10%;
        margin-left: 7.2%;
        margin-bottom: auto;
        padding-bottom: 1%;
        margin-top: 0%;
    }
    
    .product-link {
        margin-top: 2%;
        margin-bottom: -2% !important;
        margin-left: -22.5% !important;
        
    }

    .SolutionNav{
        text-align: center !important;
        margin-left: -21% !important
    }
    
    .api-nav{
        margin-left: -22% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .integration-nav{
        margin-left: -22% !important;
        margin-right: 0% !important;
        margin-top: -2% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .who-nav{
        margin-left: -21.5% !important;
        margin-top: -2%;
        margin-left: auto;
        background-color: rgba(255, 0, 0, 0);
    }
    
    .contact-button{
        margin-left: -21.5%;
        margin-top: 4.2%;
        margin-bottom: 5%;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.7% 2%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .contact-button:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }
}

@media (min-width: 926px) and (max-width: 992px) {
    .Toggle{
        border: none;
        margin-left: auto;
        margin-right: 5%;
        background-color: rgb(255, 255, 255);
        padding: 5%;
    }

    .Toggle:active{
        outline:  none !important;
    }
    
    .Toggle::after{
        outline: none !important;
    }
    
    .navbar-custom {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1005 !important; /* Cambia el valor de z-index */
        box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
        color: black !important;
        font-weight: bold;
        background-color: rgb(255, 255, 255) !important;
        letter-spacing: 1px !important;
        height: auto;
        padding-top: 4%;
        padding-bottom: 2%;
        
    }
    
    
    .Navbar-logo{
        width: 10%;
        margin-left: 7.2%;
        margin-bottom: auto;
        padding-bottom: 1%;
        margin-top: 0%;
    }
    
    .product-link {
        margin-bottom: -2.5% !important;
        margin-right: 0% !important;
        margin-left: -22% !important;
        
    }
    

    .SolutionNav{
        text-align: center !important;
        margin-right: 0% !important;
        margin-left: -21% !important;
    }
    
    .api-nav{
        margin-left: -22% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .integration-nav{
        margin-left: -22% !important;
        margin-right: 0% !important;
        margin-top: -2% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .who-nav{
        margin-left: -21.5% !important;
        margin-top: -2%;
        margin-left: auto;
        background-color: rgba(255, 0, 0, 0);
    }
    
    .contact-button{
        margin-top: 4.2%;
        margin-bottom: 5%;
        margin-left: -21.2% !important;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: 1.7% 2%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }
    
    .contact-button:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }
}


/* Estilos modo Desktop*/

@media (min-width: 992px) {


    .navbar-custom {
        position: fixed;
        width: 100%;
        height: 16%;
        top: 0;
        padding-top: 2%;
        padding-bottom: 1.6%;
        color: black !important;
        background-color: rgb(255, 255, 255) !important;
        box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
        font-weight: 700 !important;
        letter-spacing: 1.5px !important;
        z-index: 1005 !important;       
    }

    .Navbar-logo{
        width: 6vw;
        margin-left: 9.1%;
        margin-top: -0.4%;
        margin-right: 3.54%;
        cursor: pointer;
    }

    .me-auto{
        margin-top: 5%;
        display: 'inline-block' !important;
    }

    .contact-button{
        margin-top: 1.5%;
        margin-right: 10.5%;
        background-color: rgb(255, 159, 28);
        border: 3px solid rgb(255, 159, 28);
        border-radius: 15px;
        padding: .7% 1%;
        color: white;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.201);
    }

    .contact-button:hover {
        background-color: rgb(255, 255, 255);
        border: 3px solid rgb(255, 159, 28);
        color: rgb(255, 159, 28);
    }

    .product-link {
        margin-right: 5% !important;
        
    }

    .Solution-Drop{
        padding-bottom: 3.4%;
        background-color: rgba(255, 0, 0, 0);
    }

    .Section-items{
        text-align: left !important;
        margin-top: 5% !important;
        margin-bottom: 2%;
        letter-spacing: 2px;
        font-size: 12px;
    }

    .Section-items:hover{
        color: rgb(255, 159, 28) !important;
    }


    .current{
        border-bottom: solid 4px rgb(255, 159, 28);
        width: 100%;
        border-bottom-left-radius: 8%;
        border-bottom-right-radius: 8%;
    }
    
    .api-nav{
        margin-left: 5% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .integration-nav{
        margin-left: 5.2% !important;
        margin-right: 0% !important;
        margin-top: 0% !important;
        display: 'inline-block' !important;
        background-color: rgba(255, 0, 0, 0);
    }

    .who-nav{
        margin-left: auto;
        background-color: rgba(255, 0, 0, 0);
        width: 35vh;
    }
}